<template>
  <div>
    <dialog-component
      :title="'Entities Fields'"
      :visible="relationshiopFieldsDialogVisible"
      @close="closeDialog"
    >
      <div v-loading="loading" class="popUp-content">
        <h4>New Relationships</h4>
        <el-row>
          <el-col :span="10">
            <el-table :data="newRelationshipsData" style="width: 100%">
              <el-table-column
                prop="parent_entity_id"
                label="Entity"
                width="160"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="
                      newRelationshipsData[scope.$index].parent_entity_id
                    "
                    disabled
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="child_relation_type"
                label="Has"
                width="80"
              >
                <template slot-scope="scope">
                  {{ newRelationshipsData[scope.$index].child_relation_type }}
                </template>
              </el-table-column>

              <el-table-column
                prop="child_entity_id"
                label="Child Entity"
                width="160"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="newRelationshipsData[scope.$index].child_entity_id"
                    disabled
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column
                prop="child_relation_title"
                label="As"
                width="140"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="
                      newRelationshipsData[scope.$index].child_relation_title
                    "
                  ></el-input>
                </template>
              </el-table-column> </el-table
          ></el-col>

          <el-col :span="14">
            <el-table :data="newRelationshipsData" style="width: 100%">
              <el-table-column
                prop="parent_entity_id"
                label="Entity"
                width="160"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="newRelationshipsData[scope.$index].child_entity_id"
                    disabled
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="child_relation_type"
                label="Has"
                width="120"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="
                      newRelationshipsData[scope.$index].parent_relation_type
                    "
                  >
                    <el-option label="MANY" value="MANY"></el-option>
                    <el-option label="ONE" value="ONE"></el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column
                prop="child_entity_id"
                label="Child Entity"
                width="180"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="
                      newRelationshipsData[scope.$index].parent_entity_id
                    "
                    disabled
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column
                prop="parent_relation_title"
                label="As"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="
                      newRelationshipsData[scope.$index].parent_relation_title
                    "
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="parent_relation_title"
                label="Actions"
                width="150"
              >
                <template slot-scope="scope">
                  <el-button
                    icon="el-icon-check"
                    @click="addRelationship(scope.$index)"
                    type="success"
                    size="mini"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="relationshiopFieldsDialogVisible = false"
          >Close
        </el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["entities", "fields"],
  data() {
    return {
      relationshiopFieldsDialogVisible: false,
      childEntitiesData: [],
      selectedChildEntitiesData: [],
      childPrimaryFields: [],
      loading: false,
      newRelationshipsData: [],
    };
  },
  computed: {
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("entityRelationships", [
      "getAllExistedEntityRelationships",
      "getEntityRelationshipAddStatus",
      "getEntityRelationships",
      "getEntityRelationshipUpdateStatus",
      "getEntityRelationshipDeleteStatus",
    ]),

    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  mounted() {
    this.fetchDetails();
  },

  methods: {
    async fetchDetails() {
      this.entities.forEach((entity) => {
        this.fields.forEach((field) => {
          let relationship = {
            entity: entity,
            parent_entity_id: entity._id,
            child_entity_id: field.entity_id,
            child_relation_title: field.label,
            child_relation_type: "ONE",
            parent_relation_title: entity?.name ? entity.name : "",
            parent_relation_type: "ONE",
            is_bidirectional: true,
            owner_type: "PARENT",
            representation: "FIELD",
            field_id: field._id,
          };
          this.newRelationshipsData.push(relationship);
        });
      });

      // we have to remove existed relationships

      await this.$store.dispatch("entities/fetchEntities", {
        get_all: true,
      });
      this.allEntities = [...this.getAllEntities.data];
      this.otherEntities = this.allEntities.filter(
        (x) => x._id != this.$route.params.entity_id
      );

      let relationshipFilter = this.newRelationshipsData.map((e) => {
        return {
          parent_entity_id: e.parent_entity_id,
          child_entity_id: e.child_entity_id,
        };
      });

      await this.fetchEntityRelationships(relationshipFilter);

      let existedRelationships = this.getAllExistedEntityRelationships;

      this.newRelationshipsData = this.newRelationshipsData.filter((e) => {
        let foundIndex = existedRelationships.findIndex((f) => {
          return (
            (f.parent_entity_id == e.parent_entity_id &&
              f.child_entity_id == e.child_entity_id) ||
            (f.parent_entity_id == e.child_entity_id &&
              f.child_entity_id == e.parent_entity_id)
          );
        });
        return foundIndex == -1 ? true : false;
      });

      if (this.newRelationshipsData.length) {
        this.showAssignEntities();
      } else {
        this.closeDialog();
      }
    },

    async fetchEntityRelationships(relationshipFilter) {
      await this.$store.dispatch(
        "entityRelationships/fetchAllExistedRelationships",
        {
          relationships_data: relationshipFilter,
        }
      );
    },

    async showAssignEntities() {
      this.relationshiopFieldsDialogVisible = true;
    },

    closeDialog() {
      this.$emit("close", true);
    },

    async addRelationship(index) {
      try {
        this.newRelationship = { ...this.newRelationshipsData[index] };
        await this.addNewRelationship();
        this.newRelationshipsData.splice(index, 1);
      } catch (err) {
        console.log("addRelationship",err);
      }
    },

    async addNewRelationship() {
      this.loading = true;
      this.loadingText = "Adding Relationship";
      try {
        await this.$store.dispatch(
          "entityRelationships/addEntityRelationship",
          {
            ...this.newRelationship,
          }
        );
        this.loading = false;

        if (this.getEntityRelationshipAddStatus) {
          this.$message.success("Entity Relationship added successfully");
        }
      } catch (err) {
        this.loading = false;
        console.error("addNewRelationship",err);
        throw err;
      }
    },
  },
  watch: {
    existedData(newValue) {
      // ...
      console.log(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-buttons {
  border: 1.5px solid #285ed3;
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: #285ed3;
  &:hover {
    background-color: #285ed3;
    color: #ffffff;
    border-color: #285ed3;
  }
}
</style>